import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function InscriptionPage() {
  const [annivDate, setAnnivDate] = useState("");
  const [warningBlock, setWarning] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  //EmailJS Variable
  const [loading, setLoading] = useState(false);

  const Nom = useRef();
  const Prenom = useRef();
  const Adresse = useRef();
  const Tel = useRef();
  const DateAnniv = useRef();
  const CharteRef = useRef();
  const CharteMineurRef = useRef();
  const HP = useRef();

  //EmailJS
  useEffect(() => emailjs.init("u3LK1Gyh_Pp3J2-In"), []);

  async function onSubmitFinal(e) {
    e.preventDefault();
    if (HP.current.value !== "") {
      console.log("bot");
      // C'est un bot, ignorer la soumission
      return;
    }
    // Conversion de l'age
    const [year, month, day] = DateAnniv.current.value.split("-");

    // Conversion des valeurs des checkbox en format texte logique
    const Charte = CharteRef.current.checked
      ? "Validé par la personne"
      : "Non validé";

    let CharteMineur;
    if (CharteMineurRef.current !== undefined) {
      CharteMineur = CharteMineurRef.current.checked
        ? "Validé par la personne"
        : "Non validé";
    }

    const serviceId = "service_yx7jq4n";
    const templateId = "template_xihqwvb";

    try {
      setLoading(true);

      // Préparation des données à envoyer
      const emailData = {
        nom: Nom.current.value,
        prenom: Prenom.current.value,
        adresse: Adresse.current.value,
        tel: Tel.current.value,
        dateAnniv: `${day}.${month}.${year}`,
        charte: Charte,
      };

      // Ajout conditionnel de CharteMineur aux données à envoyer
      if (CharteMineur !== undefined) {
        emailData.charteMineur = CharteMineur;
      } else {
        emailData.charteMineur = "Majeur";
      }

      // Envoi de l'email
      await emailjs.send(serviceId, templateId, emailData);
      setConfirmation(true); // Affiche le message de confirmation
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (event) => {
    setAnnivDate(event.target.value);
    checkAge(event.target.value, event); // Appel de la fonction de vérification à chaque changement de date
  };

  // Fonction pour vérifier l'âge
  const checkAge = (date, event) => {
    const inputDate = new Date(date);
    const currentDate = new Date();
    const ageDifferenceYears =
      currentDate.getFullYear() - inputDate.getFullYear();
    const inputMonth = inputDate.getMonth();
    const currentMonth = currentDate.getMonth();
    const inputDay = inputDate.getDate();
    const currentDay = currentDate.getDate();

    let ageDifference = ageDifferenceYears;

    if (
      currentMonth < inputMonth ||
      (currentMonth === inputMonth && currentDay < inputDay)
    ) {
      ageDifference--;
    }
    // Validation HTML5 intégrée
    const inputElement = event.target;
    if (ageDifference < 16) {
      console.log(ageDifference);
      inputElement.setCustomValidity(
        "Vous devez avoir au moins 16 ans pour vous inscrire."
      );
      toast.error("Vous devez avoir au moins 16 ans pour vous inscrire.", {
        position: "bottom-center",
      });
      setWarning(false);
    } else if (ageDifference >= 16 && ageDifference < 18) {
      inputElement.setCustomValidity("");

      setWarning(true);
    } else {
      setWarning(false);
      inputElement.setCustomValidity("");
      console.log(warningBlock);
    }
  };
  const defaultYear = () => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    return currentDate.toISOString().split("T")[0]; // Format YYYY-MM-DD
  };
  return (
    <div className="animate-fade-up  px-6 my-6 mx-auto max-w-xl bg-white font-[sans-serif]">
      <Helmet>
        <title>Inscription - Jeunesse de Savièse</title>
        <meta
          name="description"
          content="Page d'inscription des nouveaux membres de la jeunesse de Savièse"
        />
        <meta
          name="keywords"
          content="jeunesse, Savièse, Valais, Inscription dans la jeunesse de Savièse, Jeunesse de Savièse inscription"
        />
      </Helmet>
      <h1 className="text-3xl text-[#333] font-extrabold text-center space-y-8 pb-5">
        Formulaire d'inscription
      </h1>
      <form className="mt-8 space-y-4" onSubmit={onSubmitFinal}>
        <input
          type="text"
          placeholder="Nom"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 "
          ref={Nom}
          required
        />
        <input
          type="text"
          placeholder="Prénom"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 "
          ref={Prenom}
          required
        />
        <input
          type="text"
          placeholder="Adresse"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 "
          ref={Adresse}
          required
        />

        <div className="relative">
          <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 19 18"
            >
              <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
            </svg>
          </div>
          <input
            type="text"
            aria-describedby="helper-text-explanation"
            className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 ps-10 p-2.5 "
            pattern="[0-9]{10}"
            placeholder="0791234567"
            ref={Tel}
            required
          />
        </div>

        <input
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 "
          type="date"
          name="Date"
          id="dateStarter"
          ref={DateAnniv}
          onChange={handleChange}
          defaultValue={defaultYear()} // Setting default year to 2002
          required
        />

        <div>
          <input
            id="link-checkbox"
            type="checkbox"
            className="w-4 h-4  rounded focus:ring-blue-500 required"
            ref={CharteRef}
            required
          />
          <label className="ms-2  font-medium  ">
            J'accepte la{" "}
            <Link to="/charte">
              <a className="text-blue-600 dark:text-blue-500 hover:underline">
                charte
              </a>{" "}
            </Link>
            de la jeunesse de Savièse.
          </label>
          <input
            type="text"
            name="hp"
            ref={HP}
            style={{
              position: "absolute",
              left: "-9999px",
              top: "auto",
              width: "1px",
              height: "1px",
              overflow: "hidden",
            }}
            tabIndex="-1"
            autoComplete="off"
          />

          {warningBlock && (
            <>
              <br />
              <input
                id="link-checkbox"
                type="checkbox"
                className="w-4 h-4  rounded focus:ring-blue-500 required"
                ref={CharteMineurRef}
                required
              />
              <label className="ms-2  font-medium  ">
                J'ai lu et j'accepte{" "}
                <Link to="/responsabilite">
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    les conditions de responsabilité
                  </a>{" "}
                </Link>
                de la jeunesse de Savièse.
              </label>
            </>
          )}
        </div>

        <p
          id="helper-text-explanation"
          className="mt-2  text-gray-500 dark:text-gray-400 underline decoration-1 "
        >
          Pour pouvoir s'inscrire il faut au minimum avoir 16 ans
        </p>

        <button
          type="submit"
          className="px-6 py-3 mb-8  rounded text-white text-lg tracking-wider font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300  "
        >
          Envoyer
        </button>
      </form>
      {loading ? (
        <div className="  px-4  fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-0 z-50">
          <div className="bg-white shadow-2xl border-4 border-[#ff6348] border-2 border-solid p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
            <h2 className="text-xl font-semibold items-center w-full">
              Envoi en cours...
            </h2>
            <div className="flex mt-4 justify-center items-center w-full">
              <div className="flex animate-spin rounded-full h-12 w-12 border-t-4 border-[#ff6348]"></div>
            </div>
          </div>
        </div>
      ) : (
        confirmation && (
          <div className="px-4 fixed inset-0 flex flex-auto justify-center items-center bg-gray-800 bg-opacity-0 z-50">
            <div className="g-white shadow-2xl border-4 border-[#ff6348] border-2 border-solid bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
              <h2 className="text-2xl font-semibold">
                Merci de votre inscription
              </h2>
              <p className="mt-4">
                Nous avons bien reçu votre inscription. Nous vous ajouterons
                dans les plus brefs délais à notre liste de membres.
              </p>
              <button
                onClick={() => setConfirmation(false)} // Ferme le modal
                className="mt-4 px-4 py-2 bg-[#ff6348] text-white rounded hover:bg-[#ff6348]-700"
              >
                Fermer
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default InscriptionPage;
