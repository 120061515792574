import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function ChartePage() {
  return (
    <div className="animate-fade-up max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
      <Helmet>
        <title>Charte - Jeunesse de Savièse</title>
        <meta name="description" content="Page pour la charte de la jeunesse" />
      </Helmet>
      <div className="text-left max-w-2xl mx-auto">
        <Link
          to="/inscription"
          className="text-[#ff6348] hover:underline mb-4 flex items-center"
        >
          <FaArrowLeft className="mr-2 ml-3 " /> Retour
        </Link>
        <h2 className="text-3xl font-extrabold text-center mb-4">
          Charte de la jeunesse de Savièse{" "}
        </h2>
        <p className="text-lg text-justify px-8">
          Par la présente charte, je m’engage auprès de la jeunesse de Savièse à
          respecter tous les membres et de payer une cotisation annuelle de CHF
          50.-. <br />
          <br />
          Une assemblée générale est tenue 1 fois par année afin de présenter
          les comptes et de repasser en revue les sorties de l’année, les
          changements du comité et la présentation des futures sorties. <br />
          <br />
          Pour toutes personnes mineures l'accord du représentant légal est
          requise car nous déclinons toutes responsabilités en cas de problème.
          Nous pensons que chaque personne est responsable.
        </p>
      </div>
    </div>
  );
}

export default ChartePage;
